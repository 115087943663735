'use strict';

var viewPortHelper = require('../../../helpers/viewport');

/**
 * Initialize tabbed content
 */
function initTabs() {
    // Get all tabbedContent instances
    var $tabGroups = $('.experience-commerce_layouts-tabbedContent');
    var dragMoved = false; // monitor if tab is being dragged or clicked on mobile
    $tabGroups.each(function (idx) {
        // Get tabs & panels for the tab group
        var $tabs = $(this).find('.tabs .experience-commerce_assets-tab');
        var $panels = $(this).find('.tab-panels .experience-commerce_layouts-tabPanel');

        // Set tab nav
        $tabs.each(function (tabIdx) {
            var tabID = 'tab-' + idx + '-' + tabIdx;
            var defaultMobileState = $($tabs[tabIdx]).find('.tab').attr('data-defaultActive');

            // Set tab attributes
            $(this).attr({
                'data-id': tabID,
                'aria-controls': tabID,
                'aria-expanded': 'false'
            });

            // Set panel attributes | mobile tab labels
            $($panels[tabIdx]).attr('id', tabID).find('.tab-panel .tab-anchor--mobile')
                .attr('data-id', tabID).text($($tabs[tabIdx]).find('.tab-anchor').text()).removeClass('d-none')
                .parent().attr('data-defaultActive', defaultMobileState);


            // Set tab action
            $(this).find('.tab-anchor').on('click', function() {
                if (!dragMoved){
                    $($tabGroups[idx]).find('.tabs .js-active').removeClass('js-active').attr('aria-expanded', function(i, attr){
                        return attr == 'true' ? 'false' : 'true';
                    });
                    $($tabGroups[idx]).find('.tab-panels .js-active').removeClass('js-active').attr('aria-expanded', function(i, attr){
                        return attr == 'true' ? 'false' : 'true';
                    }).find('.tab-anchor--mobile').attr('aria-expanded', function(i, attr){
                        return attr == 'true' ? 'false' : 'true';
                    });

                    $($tabs[tabIdx]).addClass('js-active').attr('aria-expanded', function(i, attr){
                        return attr == 'true' ? 'false' : 'true';
                    });

                    $($panels[tabIdx]).addClass('js-active').attr({
                        'aria-expanded': function(i, attr){
                            return attr == 'true' ? 'false' : 'true';
                        }
                    }).find('.fade-in-up').addClass('visible');
                }
            });

        });

        // Set panel mobile nav
        $panels.each(function (pnlIdx) {
            // Set mobile tab action
            $(this).find('.tab-anchor--mobile').on('click', function() {

                $($tabs[pnlIdx]).toggleClass('js-active').attr({
                    'aria-expanded': function(i, attr){
                        return attr == 'true' ? 'false' : 'true';
                    }
                });

                $($panels[pnlIdx]).toggleClass('js-active').attr({
                    'aria-visible': function(i, attr){
                        return attr == 'true' ? 'false' : 'true';
                    }
                });
            });
        });

        // Set defaults on init
        if (viewPortHelper.isDesktop()) {
            // Set first active tab
            $($tabs[0]).addClass('js-active').attr('aria-expanded', true);
            $($panels[0]).addClass('js-active').attr('aria-visible', true);
        } else {
            // Set active tabs based on default value configured for mobile
            $panels.each(function (pnlIdx) {
                var defaultActive = $(this).find('.tab-panel').attr('data-defaultactive');

                if (defaultActive === 'true') {
                    $($tabs[pnlIdx]).addClass('js-active').attr('aria-expanded', true);
                    $($panels[pnlIdx]).addClass('js-active').attr('aria-visible', true);
                }
            });
        }

    });

    $(window).smartresize(function() {
        // Mobile tab functionality allows multiple active tabs (accordion UI) but desktop functionality only allows a single active tab.
        // Multiple active tabs should be cleared on desktop in the case where resize triggers desktop viewport and multiple active tabs are present.

        if (viewPortHelper.isDesktop()) {
            $($tabGroups).each(function(index) {
                // Get tabs & panels for the tab group
                var activeTabs = $(this).find('.tabs .experience-commerce_assets-tab.js-active');
                var activePanels = $(this).find('.tab-panels .experience-commerce_layouts-tabPanel.js-active');
                var activePanelCount = activePanels.length;

                // Clear multiple active tab panels if present
                if (activePanelCount > 0) {
                    // Keep only first active tab
                    activeTabs.each(function(tIdx) {
                        if (tIdx > 0) {
                            $(this).removeClass('js-active').attr('aria-expanded', false);
                        }
                    });
                    // Keep only first active panel
                    activePanels.each(function(pIdx) {
                        if (pIdx > 0) {
                            $(this).removeClass('js-active').attr('aria-visible', false);
                        }
                    });
                } else {
                    // Activate first tabs if no active tabs exist
                    $(this).find('.tabs .experience-commerce_assets-tab').first().addClass('js-active').attr('aria-expanded', true);
                    $(this).find('.tab-panels .experience-commerce_layouts-tabPanel').first().addClass('js-active').attr('aria-visible', true);
                }
            });
        }
    });
    
    // get all tabs that are tab view enabled for mobile
    var $mobileTabs = $(".tab-view-enabled .tabs.row");
    $mobileTabs.each(function () {
        const parent = $(this);
        const draggable = parent.find(".draggableTabs");

        let isDragging = false;
        let startX = 0;
        let currentTranslateX = 0;
        let newTranslateX = 0;

        let parentWidth = 0;
        let draggableWidth = 0;

        let minDrag = 0;
        const maxDrag = 0;

        // Recalculate dimensions and boundaries
        const updateDimensions = () => {
            parentWidth = parent.width();
            draggableWidth = draggable.get(0).scrollWidth;
            minDrag = -(draggableWidth - parentWidth);

            // Ensure draggable doesn't exceed boundaries on resize
            if (currentTranslateX < minDrag) currentTranslateX = minDrag;
            if (currentTranslateX > maxDrag) currentTranslateX = maxDrag;

            draggable.css("transform", `translateX(${currentTranslateX}px)`);
        };

        $(window).on("load resize", updateDimensions);

        // Handle start of drag
        const startDrag = (pageX) => {
            isDragging = true;
            startX = pageX;
            draggable.css("cursor", "grabbing");
        };

        // Apply transform with requestAnimationFrame
        const applyTransform = () => {
            draggable.css("transform", `translateX(${newTranslateX}px)`);
        };

        // Handle dragging
        const doDrag = (pageX) => {
            if (!isDragging) return;

            const deltaX = pageX - startX;
            newTranslateX = currentTranslateX + deltaX;

            // Clamp to boundaries
            if (newTranslateX > maxDrag) newTranslateX = maxDrag;
            if (newTranslateX < minDrag) newTranslateX = minDrag;

            requestAnimationFrame(applyTransform);
        };

        // Handle end of drag
        const endDrag = () => {
            if (!isDragging) return;

            isDragging = false;
            currentTranslateX = newTranslateX;
            draggable.css("cursor", "grab");
        };

        // Mouse events
        draggable.on("mousedown", function (e) {
            startDrag(e.pageX);
        });

        $(document).on("mousemove", function (e) {
            doDrag(e.pageX);
        });

        $(document).on("mouseup", function () {
            endDrag();
        });

        // Touch events
        draggable.on("touchstart", function (e) {
            const touch = e.originalEvent.touches[0];
            startDrag(touch.pageX);
        });

        $(document).on("touchmove", function (e) {
            if (!isDragging) return;
            const touch = e.originalEvent.touches[0];
            doDrag(touch.pageX);
        });

        $(document).on("touchend", function () {
            endDrag();
        });

        // Initialize dimensions on page load
        updateDimensions();
    });

}

$(document).ready(function () {
    initTabs();
});
